/**
 * @file API calls.
 */
// TODO: Many of these api calls need to be using post bodies instead of query string (too big).
import $ from 'jquery';
import { IrUtils } from "../utils/utils.es13.js"

const CONTENT_TYPE_JSON = 'application/json; charset=utf-8';
const defaultApiAjaxArgs = { dataType: 'json', method: 'POST' };
const defaultApiAjaxArgsGet = $.extend({}, defaultApiAjaxArgs, { method: 'GET' });
const defaultLoginError = { name: 'error', message: 'Login failed.' };
const rootApiUrl = window.siteSettings.InfinityyApiUrl;
const websiteUrl = rootApiUrl;
const loginUrl = `${rootApiUrl}Account/LoginJson`;
const saveMessageUrl = `${rootApiUrl}api/ChatMessage`;

function toV4ContactInformation(pascalCaseContactInformation) {
	if (!pascalCaseContactInformation) {
		return null;
	}
	const v4 = {};
	if (pascalCaseContactInformation.DisplayName) {
		v4.name = pascalCaseContactInformation.DisplayName;
	}
	if (pascalCaseContactInformation.EmailAddress) {
		v4.email = pascalCaseContactInformation.EmailAddress;
	}
	if (pascalCaseContactInformation.PhoneNumber) {
		v4.phone = pascalCaseContactInformation.PhoneNumber;
	}

	return v4;
}

class XhrError extends Error {
	constructor(jqXHR, textStatus, errorThrown) {
		super(errorThrown);
		this.errorThrown = errorThrown;
		this.jqXHR = jqXHR;
		this.name = 'XhrError';
		this.textStatus = textStatus;
	}
}

const randomColors = ["#DB4437", "#E91E63", "#9C27B0", "#673AB7", "#3F51B5", "#4285F4", "#039BE5", "#0097A7", "#009688",
	"#0F9D58", "#689F38", "#EF6C00", "#FF5722", "#757575"];
function getRandomColor() {
	return randomColors[Math.floor(Math.random() * randomColors.length)];
}

// options = ownerUsername, authToken, name, email, phone
function createLead(options) {
	if (!options.loggedInUsername) {
		return Promise.resolve();
	}
	options = $.extend({}, options, { color: getRandomColor() });
	return $.ajax(`${rootApiUrl}api/leads?${$.param(IrUtils.shallowStripNullies(options))}`, defaultApiAjaxArgs);
}

function getChatInviteInfo(contentId, username) {
	const params = {
		contentType: contentId.type,
		contentId: contentId.id,
		userName: username
	};
	return $.ajax(`${rootApiUrl}api/viewercontent/chatInviteInfo?${$.param(params)}`, defaultApiAjaxArgsGet);
}

/**
 * Get content data for a project (only supported content type currently).
 * @param {{id: number, type: string}} contentId
 * @return {Promise<object>} Promise resolving successfully with the content object or rejecting with error.
 */
function getContent(contentId) {
	if (!contentId || !['campaign', 'project'].includes(contentId.type) || !contentId.id) {
		return Promise.reject({ name: 'UnsupportedContent', message: 'Invalid or unsupported content type or id.' });
	}
	return $.ajax(`${rootApiUrl}api/viewercontent/${contentId.type}s/${contentId.id}`, defaultApiAjaxArgsGet);
}

/**
 * Get a shortened link URL.
 * @param {string} longUrl
 * @return {Promise<{key: string, url: string>} Promise resolving successfully with the shortened URL and key.
 */
function getShortenedUrl(longUrl) {
	return new Promise((resolve, reject) => {
		$.ajax(`${rootApiUrl}goto?${$.param({ pathAndQuery: longUrl })}`, defaultApiAjaxArgs)
			.done(function (key) {
				resolve({
					key: key,
					url: `${websiteUrl}goto/${key}`
				});
			}).fail((jqXHR, textStatus, errorThrown) => reject(new XhrError(jqXHR, textStatus, errorThrown)));
	});
}

/**
 * Get a user's profile.
 * @param {string=} authToken
 * @returns {Promise} response returning {avatarPhotoUrl: string=, displayName: string=, preferredEmailClient: number=, username: string=}
 */
function getUser(authToken) {
	return new Promise((resolve, reject) => {
		$.ajax(`${rootApiUrl}api/agents/me?${$.param({ authToken: IrUtils.toEmptyStringIfNully(authToken) })}`, defaultApiAjaxArgsGet)
			.done((data) => {
				data = data || {};
				resolve({
					avatarPhotoUrl: data.AvatarPhotoUrl,
					displayName: data.FormalName,
					emailAddress: data.Login || data.Email,
					identityId: data.IdentityId,
					preferredEmailClient: data.PreferredEmailClient,
					username: data.UserName
				});
			})
			.fail((jqXHR, textStatus, errorThrown) => {
				reject(new XhrError(jqXHR, textStatus, errorThrown));
			});
	});
}

/**
 * Login.
 * @param {string} username
 * @param {string} password
 * @return {Promise} On failure, a {name: {string}, message: {string}} will be the result.
 * {authToken: {{domain, expires, name, path, value}}} on success.
 */
function login(username, password) {
	return new Promise((resolve, reject) => {
		$.ajax(loginUrl, $.extend({}, defaultApiAjaxArgs, { data: { username: username, password: password } }))
			.done((data) => {
				if (!data.success) {
					reject(defaultLoginError);
				} else {
					resolve({ authToken: data.authToken });
				}
			})
			.fail(() => reject(defaultLoginError));
	});
}

/**
 * Save a chat message to the database
 * @param {any} messageData - a chat message DTO object
 */
function saveChatMessage(messageData) {
	return $.ajax(saveMessageUrl, {
		data: messageData,
		dataType: 'json',
		method: 'PUT'
	});
}


/**
 * Send an SMS message to a single phone number.
 * @param {string} message
 * @param {string} phoneNumber
 */
function sendSmsMessage(message, phoneNumber) {
	// TODO: this is a security hole, and also these args should be in post body not url (they're big).
	return $.ajax(`${rootApiUrl}api/twilio/smsNotification?${$.param({ messageText: message, toPhoneNumber: phoneNumber })}`,
		defaultApiAjaxArgs);
}

/**
 * InfinityyRoom API.
 * TODO: Migrate more of the function calls to this "namespaced" single export.
 * @class
 */
class IrApi {
	/**
	 * @example new IrApi(window.siteSettings.InfinityyApiUrl)
	 * @param {string} rootUri
	 */
	constructor(rootUri) {
		this._rootUri = rootUri.endsWith('/') ? rootUri : (rootUri + '/');
	}

	/**
	 * Get an updated version of {val}, given type and domain
	 * @param {any} type - the type of mapping, e.g. 'matterport'
	 * @param {any} domain - the mapping domain, e.g. a matterport modelId
	 * @param {any} val - the value to map
	 * @param {any} invert - if true, interpret {val} as the B column instead of the A column
	 * @returns
	 */
	getMapping(type, domain, val, invert) {
		const qs = invert ? `?${$.param({ invert: 'true' })}` : '';
		const url = `${this._rootUri}api/v4/mappings/${type}/${domain}/${val}${qs}`
		return new Promise((resolve, reject) => {
			$.ajax(url, $.extend({}, defaultApiAjaxArgsGet))
				.done((data) => {
					if (data?.status?.success) {
						resolve(data.result);
					} else {
						reject(new Error(`Unsuccessful api call: ${url}`));
					}
				})
				.fail((jqXHR, textStatus, errorThrown) => {
					reject(new XhrError(jqXHR, textStatus, errorThrown));
				});
		});
	}

	/**
	 * @param {object} options
	 * @param {string=} options.AuthToken
	 * @param {object=} options.IdentityToken
	 * @param {string=} options.IdentityToken.Guid
	 * @param {number=} options.IdentityToken.Timestamp
	 * @returns {Promise}
	 */
	getOrCreateIdentity(options) {
		let request = null;
		if (options?.AuthToken || options?.IdentityToken?.Guid) {
			request = {
				ClientToken: {}
			};
			if (options.AuthToken) { request.ClientToken.AuthToken = options.AuthToken }
			if (options.IdentityToken?.Guid) {
				request.ClientToken.IdentityToken = {
					Guid: options.IdentityToken.Guid
				};
				if (options.IdentityToken.Timestamp) {
					request.ClientToken.IdentityToken.Timestamp = options.IdentityToken.Timestamp;
				}
			}
		}

		const leadEmail = window.pageInitialization?.initialUrl?._leadEmail;
		if (leadEmail) {
			request = request || {};
			request.Email = leadEmail;
		}

		return new Promise((resolve, reject) => {
			$.ajax(this._rootUri + 'api/v3/identity/getOrCreate',
				$.extend({}, defaultApiAjaxArgs, {
					contentType: CONTENT_TYPE_JSON,
					data: request ? JSON.stringify(request) : undefined,
					dataType: 'json'
				}))
				.fail((jqXhr, status, error) => {
					reject(jqXhr, status, error);
				})
				.done((data, status, jqXhr) => {
					if (!data) {
						reject(jqXhr, status);
					} else {
						resolve(data);
					}
				});
		});
	}

	/**
	 * Record a ChatRoomAction.
	 * @param {string} actionName
	 * @param {object} actionObject - JSON that ends up in ChatRoomActions.ActionJson
	 * @param {boolean} triggersNotification
	 * @param {ActivityOptions} options
	 * @param {boolean?} sendBeacon - send this as a navigator.sendBeacon request (on page close).
	 * @returns {jqXHR|XMLHttpRequest}
	 */
	sendAction(actionName, actionObject, triggersNotification, options, sendBeacon) {
		const action = {
			ActionJson: JSON.stringify(actionObject),
			ActionType: actionName,
			ActorDisplayName: options.userDisplayName,
			ActorIdentityId: options.userIdentityId || 0,
			ChatRoomId: +(options.roomId),
			ClientInstanceId: options.clientInstanceId,
			Timestamp: (options.timestamp || new Date()).toISOString(),
			TriggersNotification: triggersNotification
		};
		let url = `${this._rootUri}api/chatroomactions`;
		if (options.userAuthToken) { url += '?' + $.param({ authToken: options.userAuthToken }); }
		return (!!sendBeacon && navigator.sendBeacon) ?
			navigator.sendBeacon(url, JSON.stringify(action)) :
			$.ajax(url, $.extend({}, defaultApiAjaxArgs, { contentType: CONTENT_TYPE_JSON, data: JSON.stringify(action) }));
	}

	/**
	 * Send a user/session/browser activity to the new v4 api call on the server, which may return
	 * additional actions that need to be taken by this client-side code (report GA4 event or not).
	 * @param {string} activityName
	 * @param {object=} apiContext.clientToken
	 * @param {string=} apiContext.clientToken.authToken
	 * @param {string=} apiContext.clientToken.identityToken.guid
	 * @param {Date=} apiContext.clientToken.identityToken.timestamp
	 * @returns {jqXHR|XMLHttpRequest}
	 */
	sendAddActivity(activityName, placeId, apiContext) {
		const request = {
			context: apiContext,
			params: {
				activityName: activityName,
				placeId: placeId
			}
		};
		const url = `${this._rootUri}api/v4/activity/add-activity`;
		return $.ajax(url, $.extend({}, defaultApiAjaxArgs,
			{ contentType: CONTENT_TYPE_JSON, data: JSON.stringify(request) }));
	}

	sendGoogleAnalyticsEvent(eventName, data) {
		if (typeof (window.gtag) === 'function') {
			window.gtag('event', eventName, data);
		}
	}

	/**
	 * Generate a direct notification to content owners (sms/email).
	 * @param {string} messageCode
	 * @param {ActivityOptions} options
	 * @returns {jqXHR}
	 */
	sendDirectNotification(messageCode, options) {
		const notification = {
			ChatId: +(options.roomId),
			ContentType: options.contentType,
			MessageCode: messageCode,
			NumericContentId: options.contentNumericId,
			SenderEmail: options.senderEmail,
			SenderName: options.senderName,
			SenderPhone: options.senderPhone
		};

		let url = `${this._rootUri}api/notifications/direct`;
		if (options.authToken) { url += '?' + $.param({ authToken: options.userAuthToken }); }
		return $.ajax(url, $.extend({}, defaultApiAjaxArgs, { contentType: CONTENT_TYPE_JSON, data: JSON.stringify(notification) }));
	}

	/**
	 * Generate a "message" notification. (generates SMS/email).
	 * @param {string} message
	 * @param {ActivityOptions} options
	 * @returns {jqXHR}
	 */
	sendMessageNotification(message, options) {
		const notification = {
			ChatId: +(options.roomId),
			ContentId: options.contentId,
			ContentType: options.contentType,
			MessageSenderName: options.senderName,
			MessageText: message,
			NumericContentId: options.contentNumericId
		};

		let url = `${this._rootUri}api/notifications/messageNotifications`;
		if (options.authToken) { url += '?' + $.param({ authToken: options.userAuthToken }); }
		return $.ajax(url, $.extend({}, defaultApiAjaxArgs, { contentType: CONTENT_TYPE_JSON, data: JSON.stringify(notification) }));
	}

	/**
	 * Generate a notification
	 * @param {string} eventName
	 * @param {string} message
	 * @param {ActivityOptions} options
	 * @param {boolean?} sendBeacon - send this as a navigator.sendBeacon request (on page close).
	 * @returns {jqXHR}
	 */
	sendNotification(eventName, message, options, sendBeacon) {
		const notification = {
			ChatId: +(options.roomId),
			ContentId: options.contentId,
			ContentType: options.contentType,
			IsRead: false,
			Message: message,
			NumericContentId: options.contentNumericId,
			Source: eventName,
			Username: options.userLogin
		};

		let url = `${this._rootUri}api/notifications`;
		if (options.authToken) { url += '?' + $.param({ authToken: options.userAuthToken }); }
		return (!!sendBeacon && navigator.sendBeacon) ?
			navigator.sendBeacon(url, JSON.stringify(notification)) :
			$.ajax(url, $.extend({}, defaultApiAjaxArgs, { contentType: CONTENT_TYPE_JSON, data: JSON.stringify(notification) }));
	}

	/**
	 * Send an event to the Session Diagnostics Handler
	 * @param {string} event
	 * @param {object} eventData
	 * @returns {jqXHR}
	 */
	sendSessionDiagnosticMessage(event, eventData) {
		eventData = eventData || {};
		const data = {
			ChatRoomId: eventData.chatId,
			CurrentContentPath: eventData.contentPath || 'overlay://default',
			CurrentPositionJson: eventData.position || [],
			EventName: event,
			EventDataJson: eventData,
			ParticipantId: event.participantId
		};

		const url = `${this._rootUri}api/diagnostics`;
		return $.ajax(url, {
			data: data,
			dataType: 'json',
			method: 'POST'
		});
	}


	/**
	 * Record a visit.
	 * @param {string} userAuthToken
	 * @param {Visit} visit
	 * @param {string=} emailAddress
	 * @returns {jqXHR|XMLHttpRequest}
	 */
	sendVisit(userAuthToken, visit, emailAddress, identityId) {
		let urlArgs = {}
		if (userAuthToken) { urlArgs.authToken = userAuthToken; }
		if (emailAddress) { urlArgs.eml = emailAddress; }
		if (identityId) { urlArgs.identityId = identityId; }
		let url = `${this._rootUri}api/visits?${$.param(urlArgs)}`;
		return $.ajax(url, $.extend({}, defaultApiAjaxArgs, { contentType: CONTENT_TYPE_JSON, data: JSON.stringify(visit) }));
	}

	/**
	 * @param {object} options
	 * @param {string=} options.AuthToken
	 * @param {object=} options.IdentityToken
	 * @param {string=} options.IdentityToken.Guid
	 * @param {number=} options.IdentityToken.Timestamp
	 * @param {object} options.ContactInformation
	 * @returns {Promise}
	 */
	updateContact(options) {
		let request = null;
		if (options?.AuthToken || options?.IdentityToken?.Guid) {
			request = {
				ClientToken: {},
				ContactInformation: toV4ContactInformation(options?.ContactInformation)
			};
			if (options.AuthToken) { request.ClientToken.AuthToken = options.AuthToken }
			if (options.IdentityToken?.Guid) {
				request.ClientToken.IdentityToken = {
					Guid: options.IdentityToken.Guid
				};
				if (options.IdentityToken.Timestamp) {
					request.ClientToken.IdentityToken.Timestamp = options.IdentityToken.Timestamp;
				}
			}
		}

		return new Promise((resolve, reject) => {
			$.ajax(this._rootUri + 'api/v3/contact/update',
				$.extend({}, defaultApiAjaxArgs, {
					contentType: CONTENT_TYPE_JSON,
					data: request ? JSON.stringify(request) : undefined,
					dataType: 'json'
				}))
				.fail((jqXhr, status, error) => {
					reject(jqXhr, status, error);
				})
				.done((data, status, jqXhr) => {
					if (!data) {
						reject(jqXhr, status);
					} else {
						resolve(data);
					}
				});
		});
	}

	updateProjectViewerIdentity(projectId, identityId, chatroomId, campaignId, emailAddress) {
		return new Promise((resolve, reject) => {
			const dto = {
				projectId: projectId,
				identityId: identityId,
				chatroomId: chatroomId,
				campaignId: campaignId,
				emailAddress: emailAddress
			};
			$.ajax(`${this._rootUri}/api/v3/visitoridentity`, $.extend({}, defaultApiAjaxArgs, { data: dto }))
				.done((data) => {
					data = data || {};
					if (data.error) {
						reject(data.error);
					} else {
						resolve(data.result);
					}
				})
				.fail((jqXHR, textStatus, errorThrown) => {
					reject(new XhrError(jqXHR, textStatus, errorThrown));
				});
		});
	}
}

export { IrApi, createLead, getChatInviteInfo, getShortenedUrl, getContent, getUser, login, saveChatMessage, sendSmsMessage }
