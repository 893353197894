import $ from 'jquery';
import { normalizeGuid } from './utils/utils.es13';

const CONTENT_TYPE_JSON = 'application/json; charset=utf-8';
const DEFAULT_API_ARGS = { contentType: CONTENT_TYPE_JSON, dataType: 'json', method: 'POST' };
const DEFAULT_API_ARGS_GET = $.extend({}, DEFAULT_API_ARGS, { method: 'GET' });

class XhrError extends Error {
	constructor(jqXHR, textStatus, errorThrown) {
		super(errorThrown);
		this.errorThrown = errorThrown;
		this.jqXHR = jqXHR;
		this.name = 'XhrError';
		this.textStatus = textStatus;
	}
}

/**
 * Infinityy Room API
 */
class IrApi {
	constructor(options) {
		/** @member string */
		this._aiServiceUrl = options.aiServiceUrl;
		/** @member string */
		this._rootUrl = options.rootUrl;
		/** @member IrSession */
		this._session = options.session;
	}

	_getV4RequestContainer() {
		const clientInstanceId = window.clientInstanceId;
		const clientToken = this._session?.clientTokenV4;
		const roomId = window.pageInitialization?.initialUrl?.chatId;

		const context = {};
		if (clientInstanceId) context.clientInstanceId = clientInstanceId;
		if (clientToken) context.clientToken = clientToken;
		if (roomId) context.roomId = roomId;
		return {
			context: context
		};
	}

	addPreProjectImage(projectGuid, image) {
		const transferObject = {
			url: image.url
		};
		if (+(image.order) >= 0) { transferObject.order = +(image.order); }
		if (image.uuid) { transferObject.uuid = image.uuid; }

		return this._doV4PostRequest('/api/v4/self-service/add-image', {
			image: transferObject,
			projectGuid: projectGuid
		});
	}

	checkout(desiredSubscription) {
		return new Promise((resolve, reject) => {
			const params = this._getDefaultParams();
			$.ajax(`${this._rootUrl}/api/checkout?` + $.param(params),
				$.extend({}, DEFAULT_API_ARGS, { data: JSON.stringify(desiredSubscription) }))
				.done((data) => {
					data = data || {};
					if (data.error) {
						reject(data.error);
					} else {
						resolve(data.result);
					}
				})
				.fail((jqXHR, textStatus, errorThrown) => {
					reject(new XhrError(jqXHR, textStatus, errorThrown));
				});
		});
	}

	createOrganization(name) {
		return new Promise((resolve, reject) => {
			const params = $.extend({}, this._getDefaultParams(), { name: name });
			$.ajax(`${this._rootUrl}/api/org?` + $.param(params), $.extend({}, DEFAULT_API_ARGS, { method: 'PUT' }))
				.done((data) => {
					data = data || {};
					if (data.error) {
						reject(data.error);
					} else {
						resolve(data.result);
					}
				})
				.fail((jqXHR, textStatus, errorThrown) => {
					reject(new XhrError(jqXHR, textStatus, errorThrown));
				});
		});
	}

	_doV4PostRequest(path, requestData) {
		const request = $.extend(this._getV4RequestContainer(), requestData ?? {});
		return new Promise((resolve, reject) => {
			$.ajax(`${this._rootUrl}${path}`,
				$.extend({}, DEFAULT_API_ARGS, {
					data: JSON.stringify(request)
				}))
				.fail((jqXhr, status, error) => {
					reject(jqXhr, status, error);
				})
				.done((data, status, jqXhr) => {
					if (!data) {
						reject(jqXhr, status);
					} else {
						resolve(data);
					}
				});
		});
	}

	getOrCreatePreProject() {
		return this._doV4PostRequest('/api/v4/self-service/get-or-create-preproject');
	}

	editOrganizationProperty(organizationProperty) {
		return new Promise((resolve, reject) => {
			const params = this._getDefaultParams();
			$.ajax(`${this._rootUrl}/api/org/property?` + $.param(params),
				$.extend({}, DEFAULT_API_ARGS, { data: JSON.stringify(organizationProperty) }))
				.done((data) => {
					data = data || {};
					if (data.error) {
						reject(data.error);
					} else {
						resolve(data.result);
					}
				})
				.fail((jqXHR, textStatus, errorThrown) => {
					reject(new XhrError(jqXHR, textStatus, errorThrown));
				});
		});
	}

	editOrganizationUser(organizationUser) {
		return new Promise((resolve, reject) => {
			const params = this._getDefaultParams();
			$.ajax(`${this._rootUrl}/api/org/user?` + $.param(params),
				$.extend({}, DEFAULT_API_ARGS, { data: JSON.stringify(organizationUser) }))
				.done((data) => {
					data = data || {};
					if (data.error) {
						reject(data.error);
					} else {
						resolve(data.result);
					}
				})
				.fail((jqXHR, textStatus, errorThrown) => {
					reject(new XhrError(jqXHR, textStatus, errorThrown));
				});
		});
	}

	finalizePreProject(projectGuid) {
		return this._doV4PostRequest('/api/v4/self-service/finalize', { projectGuid: projectGuid });
	}

	findResoProperties(address) {
		return new Promise((resolve, reject) => {
			$.ajax(`${this._rootUrl}/api/v4/reso/find-property`,
				$.extend({}, DEFAULT_API_ARGS, { data: JSON.stringify({ address: address }) }))
				.fail((jqXhr, status, error) => {
					reject(jqXhr, status, error);
				})
				.done((data, status, jqXhr) => {
					if (!data) {
						reject(jqXhr, status);
					} else {
						resolve(data);
					}
				});
		});
	}

	/**
	 * Get Page Initialization Data
	 * @returns {Promise}
	 */
	getPageInitializationData() {
		const initialUrl = window.pageInitialization.initialUrl;
		const msg = {
			clientToken: this._session.clientTokenV4,
			roomId: initialUrl.chatId,
			slug: initialUrl.slug
		}

		return new Promise((resolve, reject) => {
			$.ajax(`${this._rootUrl}/api/v4/viewer/get-startup-data`,
				$.extend({}, DEFAULT_API_ARGS, { data: JSON.stringify(msg) }))
				.fail((jqXhr, status, error) => {
					reject(jqXhr, status, error);
				})
				.done((data, status, jqXhr) => {
					if (!data) {
						reject(jqXhr, status);
					} else {
						resolve(data.result);
					}
				});
		});
	}

	getChatroom(chatroomId) {
		return new Promise((resolve, reject) => {
			const params = this._getDefaultParams();
			$.ajax(`${ScapeKitRootURL}/api/chatrooms/${chatroomId}/summary?` + $.param(params), DEFAULT_API_ARGS_GET)
				.done(roomData => {
					Infinityy.BrandHome.AddChatIfUnique(roomData);
					resolve(roomData);
				});
		});
	}

	getOrganization(organizationId) {
		return new Promise((resolve, reject) => {
			let params = this._getDefaultParams();
			if ((null !== organizationId) && !isNaN(+organizationId)) {
				params.organizationId = +organizationId;
			}
			$.ajax(`${this._rootUrl}/api/org?` + $.param(params), DEFAULT_API_ARGS_GET)
				.done((data) => {
					data = data || {};
					if (data.error) {
						reject(data.error);
					} else {
						resolve(data.result);
					}
				})
				.fail((jqXHR, textStatus, errorThrown) => {
					reject(new XhrError(jqXHR, textStatus, errorThrown));
				});
		});
	}

	getPreProject(projectGuid) {
		return this._doV4PostRequest('/api/v4/self-service/get-project', { projectGuid: projectGuid });
	}

	getSlug(contentType, contentId /* numeric ID */) {
		return new Promise((resolve, reject) => {
			$.ajax(`${this._rootUrl}/api/v3/get-slug/${contentType}/${contentId}`,
				DEFAULT_API_ARGS_GET)
				.fail((jqXhr, status, error) => {
					reject(jqXhr, status, error);
				})
				.done((data, status, jqXhr) => {
					if (!data) {
						reject(jqXhr, status);
					} else {
						resolve(data);
					}
				});
		});
	}
	inviteUser(p) {
		return new Promise((resolve, reject) => {
			const params = this._getDefaultParams();
			$.ajax(`${this._rootUrl}/api/org/invite-user?` + $.param(params), $.extend({}, DEFAULT_API_ARGS, { data: JSON.stringify(p) }))
				.done((data) => {
					data = data || {};
					if (data.error) {
						reject(data.error);
					} else {
						resolve(data.result);
					}
				})
				.fail((jqXHR, textStatus, errorThrown) => {
					reject(new XhrError(jqXHR, textStatus, errorThrown));
				});
		});
	}

	regenerateDescription(longDescription, shortDescription, instructions) {
		return new Promise((resolve, reject) => {
			$.ajax(this._aiServiceUrl + 'api/v0/', $.extend({}, DEFAULT_API_ARGS, {
				contentType: CONTENT_TYPE_JSON,
				data: JSON.stringify({
					jsonrpc: '2.0',
					method: 'regenerate-description',
					id: Math.random(),
					params: {
						longDescription,
						shortDescription,
						instructions
					}
				}),
				dataType: 'json'
			})).fail((jqXhr, status, error) => {
				reject(jqXhr, status, error);
			})
			.done((data, status, jqXhr) => {
				if (!data?.result?.description) {
					reject(jqXhr, status);
				} else {
					resolve(data.result?.description);
				}
			});
		});
	}

	setNearbyPlaceCategories(projectGuid, nearbyPlaceCategories) {
		return this._doV4PostRequest('/api/v4/self-service/set-nearby-place-categories', {
			nearbyPlaceCategories: nearbyPlaceCategories,
			projectGuid: projectGuid
		});
	}

	setPreProjectAddress(projectGuid, address) {
		return this._doV4PostRequest('/api/v4/self-service/set-address', {
			address: address,
			projectGuid: projectGuid
		});
	}

	setPreProjectAiInstructions(projectGuid, instructions) {
		return this._doV4PostRequest('/api/v4/self-service/set-ai-instructions', {
			projectGuid: projectGuid,
			instructions: instructions
		});
	}

	setPreProjectGroups(projectGuid, groups) {
		return this._doV4PostRequest('/api/v4/self-service/set-groups', {
			groups: groups,
			projectGuid: projectGuid
		});
	}

	setPreProjectPlaces(projectGuid, places) {
		return this._doV4PostRequest('/api/v4/self-service/set-places', {
			places: places,
			projectGuid: projectGuid
		})
	}

	setPreProjectStructuredAttributes(projectGuid, attributes) {
		return this._doV4PostRequest('/api/v4/self-service/set-structured-attributes', {
			attributes: attributes,
			projectGuid: projectGuid
		})
	}

	updateOrganizationAccountInfo(accountInfo) {
		return new Promise((resolve, reject) => {
			const params = this._getDefaultParams();
			$.ajax(`${this._rootUrl}/api/org/account-info?` + $.param(params), $.extend({}, DEFAULT_API_ARGS, {data: JSON.stringify(accountInfo)}))
				.done((data) => {
					data = data || {};
					if (data.error) {
						reject(data.error);
					} else {
						resolve(data.result);
					}
				})
				.fail((jqXHR, textStatus, errorThrown) => {
					reject(new XhrError(jqXHR, textStatus, errorThrown));
				});
		});
	}

	updateProjectExtraJson(projectId, extraJson) {
		return new Promise((resolve, reject) => {
			const params = $.extend({}, this._getDefaultParams(), { projectId: projectId });
			$.ajax(`${this._rootUrl}/api/project/extraJson?` + $.param(params), $.extend({}, DEFAULT_API_ARGS, { data: JSON.stringify(extraJson) }))
				.done((data) => {
					data = data || {};
					if (data.error) {
						reject(data.error);
					} else {
						resolve(data.result);
					}
				})
				.fail((jqXHR, textStatus, errorThrown) => {
					reject(new XhrError(jqXHR, textStatus, errorThrown));
				});
		});
	}

	wakeSelfService() {
		return new Promise((resolve, reject) => {
			$.ajax(`${this._aiServiceUrl}self-service/wake`, $.extend({}, DEFAULT_API_ARGS, { dataType: 'text' }))
				.fail((jqXhr, status, error) => {
					reject(jqXhr, status, error);
				})
				.done((data, status, jqXhr) => {
					if (!data) {
						reject(jqXhr, status);
					} else {
						resolve(data);
					}
				});
		});
	}

	_getDefaultParams() {
		return { authToken: this._session.authToken };
	}
}

export { IrApi }
